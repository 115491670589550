import profileimg from "../../../photos/tllogo.webp";
import { Route, Routes } from "react-router";
import logground from "../../../photos/bground.jpg";
import { useEffect, useRef, useState } from "react";
import ProfileEditing from "./profileEditing";
import Transactions from "./Transactions";
import AddCar from "./AddCar";
import AddPart from "./AddPart";
import AddService from "./AddService";
import EditCar from "./EditCar";
import EditPart from "./EditPart";
import MyStatements from "./MyStatements";
import EditServ from "./EditServ";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import UserRouterList from "./UserRouterlist";
import { BsListUl } from "react-icons/bs";
import MyAlert from "../../MyAlert";
import { useContext } from "react";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";
import MyInput from "../../MyInput";

export default function User() {
  const { currency, userChooseCurrency } = useContext(CurrencyContext);
  const [showAlert, setShowAlert] = useState(false);

  const [enterBalanceBtn, setEnterBalanceBtn] = useState(false);
  const handleEnterBalanceBtn = () => {
    setEnterBalanceBtn((pre) => !pre);
  };

  const [alertStatus, setAlertStatus] = useState(false);
  const [dropLeftCol, setDropLeftCol] = useState(false);
  const handleDropLeftCol = () => {
    setDropLeftCol((pre) => !pre);
  };

  const dropRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDropLeftCol(false);
    }
  }

  const handleUploadClick = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  const [userData, setUserData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [newRender, setNewRender] = useState();

  useEffect(() => {
    axiosClient
      .get("user")
      .then((res) => {
        setUserData(res.data);
        setIsLoader(false);
        setNewRender(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [newRender]);

  return (
    <div className="w-[100%] flex flex-col items-center gap-y-[20px] pb-[100px] bg-[black]">
      <div className="w-full  flex   flex-col h-[250px] gap-3  relative">
        <img src={logground} alt="" className="w-full h-full object-cover " />
        <div className="flex justify-between px-[190px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px] text-[30px] text-white w-[100%] absolute top-[135px] ">
          <h1>პროფილი</h1>
        </div>
      </div>
      <div className="w-full relative flex  py-[55px] max-md:py-[30px] gap-[35px] max-xl:gap-[20px] px-[190px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px] max-lg:flex-col">
        <div className="w-[300px] flex flex-col gap-y-5 max-lg:hidden">
          <div className="bg-[#0e1420] shadow-md shadow-[#3d7294] flex flex-col items-center gap-5 p-7 rounded-[10px]">
            {isLoader ? (
              <div className="w-full h-full">
                <Loader />
              </div>
            ) : (
              <div className="flex w-full flex-col gap-y-[10px] items-center ">
                <div className="flex items-center gap-[10px]">
                  <div className="w-[60px] h-[60px] rounded-full flex items-center justify-center p-[2px] shadow shadow-[#3d7294]">
                    <img
                      alt=""
                      src={profileimg}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <div className="flex gap-[5px] text-white">
                    <p>ID: {userData.id}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div>
                    <p className="text-white">მომხმარებელი: {userData.name}</p>
                  </div>

                  <div className="flex items-center gap-[5px]">
                    <p className="text-white">ბალანსი:</p>
                    <p className="text-white">
                      {userChooseCurrency === "USD"
                        ? `${Math.round(userData.balance / currency.rate)} $`
                        : `${userData.balance} ₾`}
                    </p>
                  </div>
                  <div className="flex flex-col items-center gap-[10px] mt-[10px]">
                    <div
                      className={`w-full flex items-center duration-200 gap-[10px] ${
                        enterBalanceBtn ? "h-[40px]" : "h-0"
                      }`}
                    >
                      <MyInput
                        justNums={true}
                      />
                      <p className="text-white w-[40px] h-full overflow-hidden flex items-center justify-center rounded-[10px] shadow shadow-[#3d7294] bg-[#0e1420] text-[18px]">
                        ₾
                      </p>
                    </div>
                    <p
                      onClick={() =>
                        handleEnterBalanceBtn()
                      }
                      className="bg-[#0e1420] shadow overflow-hidden shadow-[#3d7294] text-white cursor-pointer duration-200 flex items-center justify-center rounded-[10px] w-full h-[40px] px-[10px]"
                    >
                      ბალანსის შევსება
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <UserRouterList setDropLeftCol={setDropLeftCol} />
        </div>

        <div className="w-[calc(100%-12px-300px)] max-lg:w-full">
          <Routes>
            <Route path="addcar" element={<AddCar />} />
            <Route
              path="editcar/:Id"
              element={
                <EditCar
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                  handleUploadClick={handleUploadClick}
                />
              }
            />

            <Route path="addpart" element={<AddPart />} />
            <Route
              path="editpart/:Id"
              element={
                <EditPart
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                  handleUploadClick={handleUploadClick}
                />
              }
            />

            <Route path="addservice" element={<AddService />} />
            <Route
              path="editservice/:Id"
              element={
                <EditServ
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                  handleUploadClick={handleUploadClick}
                />
              }
            />

            <Route path="mystatements" element={<MyStatements />} />

            <Route path="transactions" element={<Transactions />} />

            <Route path="profileediting" element={<ProfileEditing />} />
          </Routes>
        </div>
      </div>

      <div
        ref={dropRef}
        className="fixed top-0 left-0  h-full z-[11] max-lg:flex hidden"
      >
        <div
          className={`ChromePopUp bg-[black] w-[350px] py-[70px] overflow-x-hidden flex flex-col gap-y-[20px]  duration-200 ${
            dropLeftCol
              ? "px-[30px] max-tiny:px-[16px] max-tiny:w-[300px] shadow-md shadow-[white]"
              : "ml-[-350px]"
          }`}
        >
          <div className="bg-[#0e1420]  shadow-md shadow-[#3d7294] flex flex-col items-center gap-5 p-7 rounded-[10px]">
            {isLoader ? (
              <div className="w-full h-full">
                <Loader />
              </div>
            ) : (
              <div className="flex w-full overflow-x-hidden flex-col gap-y-[10px] items-center ">
                <div className="flex items-center gap-[10px]">
                  <div className="w-[60px] h-[60px]  rounded-full flex items-center justify-center p-[2px] shadow shadow-[#3d7294]">
                    <img
                      alt=""
                      src={profileimg}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <div className="flex gap-[5px] text-white">
                    <p>ID: {userData.id}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div>
                    <p className="text-white overflow-x-hidden">
                      მომხმარებელი: {userData.name}
                    </p>
                  </div>

                  <div className="flex items-center gap-[5px]">
                    <p className="text-white">ბალანსი:</p>
                    <p className="text-white">
                      {userChooseCurrency === "USD"
                        ? `${Math.round(userData.balance / currency.rate)} $`
                        : `${userData.balance} ₾`}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <UserRouterList setDropLeftCol={setDropLeftCol} />
        </div>
        <div
          onClick={() => handleDropLeftCol()}
          className="text-white cursor-pointer ml-[2px] text-[25px] mt-[230px] rounded-l-[10px] max-tiny:rounded-l-[5px] rounded-r-[25px]  max-tiny:rounded-r-[20px] flex items-center justify-center w-[50px] h-[70px] max-tiny:h-[60px] max-tiny:w-[40px] bg-[#0e1420] shadow-md shadow-[#3d7294] active:bg-[#22314f]"
        >
          <BsListUl />
        </div>
      </div>

      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "პროდუქტი წარმატებით რედაქტირდა"
            : "პროდუქტი ვერ რედაქტირდა!"
        }
      />
    </div>
  );
}

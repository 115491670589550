import { AiOutlineClose } from "react-icons/ai";

export default function UploadedVids({ item, DeleteVideoBack }) {
  return (
    <div className="flex flex-wrap gap-4 items-center">
      <div
        key={item.id}
        className={`w-full  h-[100px] shadow-md shadow-[#3d7294] bg-[#182540] group rounded-[10px] relative flex items-center
                                             `}
      >
        <div className="flex items-center w-full h-full gap-2 max-sm:flex-col">
          <video
            src={`https://support.mglparcel.com/${item.url}`}
            className="w-full h-full object-cover rounded-[6px]"
            alt="#"
            controls
          />
        </div>
        <div  onClick={() => {
          DeleteVideoBack(item.id);
        }} className="rounded-full absolute group-hover:flex max-lg:flex top-[7px] right-[10px] w-8 h-8 bg-[#21335a] cursor-pointer hidden items-center justify-center">
          <AiOutlineClose className="text-gray-100 text-2xl" />
        </div>
      </div>
    </div>
  );
}

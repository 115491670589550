import axios from "axios";

const axiosClient = axios.create({
  baseURL: `https://support.mglparcel.com/`,
});

axiosClient.interceptors.request.use((config) => {
  let token = localStorage.getItem("CarFaxUser") || localStorage.getItem("CarFaxAdmin");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("CAR-FAX");
      // window.location.reload();
    } else if (response.status === 404) {
      //Show not found
    }

    throw error;
  }
);

export default axiosClient;
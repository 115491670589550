import { useEffect, useRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axiosClient from "../axiosClient/axiosClient";
import Loader from "../loader/loader";

function NewAccount({
  setActive,
  setShowAlertReg,
  setAlertStatusReg,
  handleRegAlert,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);

  const handleshow = () => {
    setShow((pre) => !pre);
  };

  const [values, setValues] = useState({
    name: "",
    nameError: false,
    email: "",
    emailError: false,
    mobNumber: "",
    mobNumberError: false,
    password: "",
    passwordError: false,
    repeatPassword: "",
    repeatPasswordError: false,
  });

  const handelChange = (e) => {
    if (e.target.name === "mobNumber") {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/\s/g, "")
        .replace(/(.{3})/g, "$1 ")
        .trim()
        .slice(0, 11);
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handelSubmit();
    }
  };

  const handelSubmit = () => {
    setIsLoader(true);

    if (values.email && values.password) {
      axiosClient
        .post("auth/singUp", {
          name: values.name,
          phone: values.mobNumber,
          email: values.email,
          password: values.password,
        })
        .then((response) => {
          setValues({});
          setShowAlertReg(true);
          setAlertStatusReg(true);
          setActive("signin");
          setEmptyError(false);
          setError(false);
        })
        .catch((error) => {
          setError(true);
          setIsLoader(false);
          setEmptyError(false);
        })
        .finally(() => {
          setIsLoader(false);
          handleRegAlert()
        });
    } else {
      setError(false);
      setEmptyError(true);
      setIsLoader(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-[20px]">
      <div className="flex flex-col gap-y-[10px]">
        <p className=" ">სახელი</p>
        <input
          onChange={(e) => handelChange(e)}
          name="name"
          value={values.name}
          type="text"
          onKeyPress={handleInputKeyPress}
          className={`w-[100%] h-[50px] px-[10px] border-[1px] rounded-[10px] outline-none`}
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className=" ">ელ-ფოსტა</p>
        <input
          onChange={(e) => handelChange(e)}
          name="email"
          value={values.email}
          type="text"
          onKeyPress={handleInputKeyPress}
          className={`w-[100%] h-[50px] px-[10px] border-[1px] ${
            Error && "border-red-600"
          }  rounded-[10px] outline-none`}
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className=" ">ტელეფონის ნომერი</p>
        <input
          onChange={(e) => handelChange(e)}
          name="mobNumber"
          value={values.mobNumber}
          type="text"
          onKeyPress={handleInputKeyPress}
          className={`w-[100%] h-[50px] px-[10px] border-[1px] ${
            Error && "border-red-600"
          }  rounded-[10px] outline-none`}
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className=" ">პაროლი</p>
        <div
          className={`flex items-center h-[50px] px-[10px] border-[1px] rounded-[10px] outline-none`}
        >
          <input
            onChange={(e) => handelChange(e)}
            name="password"
            value={values.password}
            placeholder="***********"
            onKeyPress={handleInputKeyPress}
            type={show ? "text" : "password"}
            className="outline-none w-[100%] h-[full]"
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <AiOutlineEye className="cursor-pointer " />
            ) : (
              <AiOutlineEyeInvisible className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>

      {Error && (
        <div className="flex items-center justify-center">
          <p className="text-[#ff0000] font-bold">
            ელ-ფოსტის ან ტელ ნომრის ფორმატი არასწორია
          </p>
        </div>
      )}
      {emptyError && (
        <div className="flex items-center justify-center">
          <p className="text-[#ff0000] font-bold">შეავსეთ ყველა ველი</p>
        </div>
      )}
      {isLoader ? (
        <Loader />
      ) : (
        <div
          onClick={() => {
            handelSubmit();
          }}
          className="flex gap-[5px] items-center pt-[2px] w-[100%] h-[50px] rounded-[10px]  text-white justify-center  text-[18px] cursor-pointer hover:bg-[#000000c1]  border-[1px]  duration-200 bg-black"
        >
          <h1>რეგისტრაცია</h1>
        </div>
      )}
    </div>
  );
}
export default NewAccount;
